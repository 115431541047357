<template>
  <CommonLayout>
    <div class="cabinet">
      <div class="cabinet__inner">
        <div class="cabinet__head">
          <div class="cabinet__group">
            <div class="cabinet__heading">
              <h1 class="title">Мои идеи</h1>
              <!-- <router-link
                class="button button-default"
                :to="{ name: 'ideasFormCreate' }"
                ><span>Подать идею</span></router-link
              > -->
              <button
                v-if="getSeason.currentState === 1"
                class="button button-default"
                @click="onCreateIdea"
              >
                Подать идею
              </button>
            </div>
            <router-link
              v-if="getSeason.currentState === 3"
              class="button button-default catalog-order"
              :to="{ name: 'ideasVoting' }"
              ><span>Голосование</span></router-link
            >
            <router-link
              v-else-if="getSeason.currentState === 4"
              class="button button-default catalog-order"
              :to="{ name: 'ideasVoting' }"
              ><span>Рейтинг идей</span></router-link
            >
          </div>
          <div v-if="getSeason.filingDate" class="cabinet__row">
            <div class="cabinet__col">
              <div class="cabinet__subtitle">
                Максимальная награда:
                <div class="cabinet-balance">
                  <img
                    src="@/assets/img/pic2/coin.svg"
                    width="22"
                    height="22"
                    alt=""
                  />
                  GPB-coins:<b>{{ getSeason.maxReward }}</b>
                </div>
              </div>
            </div>
          </div>
          <IdeaTimeline />
        </div>
        <div class="cabinet__body">
          <Loading
            v-if="loading"
            position="center"
            theme="dark"
            style="width: 100%"
          />

          <div v-else-if="ideas.length" class="cabinet__items">
            <div
              v-for="(item, index) in ideas"
              :key="index"
              tag="div"
              class="cabinet__item cabinet-item"
            >
              <router-link
                class="cabinet-item__link"
                :to="{
                  name: 'ideasForm',
                  params: { id: item.id },
                }"
              ></router-link>
              <div class="cabinet-item__image"></div>
              <div class="cabinet-item__content">
                <div class="cabinet-item__caption">Идея №{{ index + 1 }}</div>
                <div class="cabinet-item__title">{{ item.title }}</div>
                <div class="cabinet-item__desc">
                  Статус: {{ item.state | formatIdea }}
                </div>
              </div>
              <div class="cabinet-item__price">
                <template v-if="item.coinsReceived">
                  Начислено:
                  <div class="cabinet-item__coins">
                    <span class="coin">
                      <img
                        src="@/assets/img/pic2/coin.svg"
                        width="15"
                        height="15"
                        alt=""
                      />
                    </span>
                    <!-- {{ COINS }} -->
                  </div>
                  <span>{{ item.coinsReceived | priceFilter }}</span>
                </template>
              </div>
              <div>
                <div class="cabinet-item__actions">
                  <router-link
                    class="button button-default cabinet-item__button"
                    :to="{
                      name: 'ideasForm',
                      params: { id: item.id },
                    }"
                    >Перейти</router-link
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-else class="items-empty">
            <img src="@/assets/img/pic2/bg-idea.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </CommonLayout>
</template>

<script>
import CommonLayout from "@/layout/CommonLayout.vue";
import Loading from "@/components/Loading.vue";
import { GetOwnIdeas, CreateIdea } from "@/api/Ideas";
import IdeaTimeline from "@/components/IdeaTimeline.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    CommonLayout,
    Loading,
    IdeaTimeline,
  },
  data() {
    return {
      loading: true,
      ideas: [],
    };
  },
  created() {
    this.IDEA_SEASON_FETCH();
    this.fetchData();
  },
  computed: {
    ...mapGetters(["getSeason"]),
  },
  methods: {
    ...mapActions(["IDEA_SEASON_FETCH"]),
    async fetchData() {
      this.loading = true;
      try {
        const resp = await GetOwnIdeas();

        this.ideas = resp.data;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    async onCreateIdea() {
      try {
        const resp = await CreateIdea({});

        this.$router.push({
          name: "ideasForm",
          params: { id: resp.data },
        });
      } catch (error) {
        const { data } = error;

        this.$modal.show("messageLarge", {
          message: data ? data : "Упс, что-то пошло не так",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.items-empty {
  img {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }
}
</style>
