<template>
  <div
    class="uni-timeline"
    :class="{
      active_1: getSeason.currentState === 1,
      active_2: getSeason.currentState === 2,
      active_3: getSeason.currentState === 3,
      active_4: getSeason.currentState === 4,
    }"
  >
    <button class="uni-timeline__item">
      <div class="uni-timeline__title">
        Подача
        <div class="uni-timeline__tooltip">
          <Tooltip
            class="goal-head__tooltip"
            :msg="`&quot;Подача&quot; - единственный этап, когда можно подавать новые идеи`"
          />
        </div>
      </div>
      <div class="uni-timeline__date">
        {{ getSeason.filingDate | formatDateBegin }}-{{
          getSeason.processingDate | formatDateEnd
        }}
      </div>
    </button>
    <button class="uni-timeline__item active">
      <div class="uni-timeline__title">
        Проверка
        <div class="uni-timeline__tooltip">
          <Tooltip
            class="goal-head__tooltip"
            :msg="`&quot;Проверка&quot; - этап, когда можно дорабатывать возвращенные модератором идеи. Подавать новые идеи уже нельзя :(`"
          />
        </div>
      </div>
      <div class="uni-timeline__date">
        {{ getSeason.processingDate | formatDateBegin }}-{{
          getSeason.votingDate | formatDateEnd
        }}
      </div>
    </button>
    <button class="uni-timeline__item">
      <div class="uni-timeline__title">
        Голосование
        <div class="uni-timeline__tooltip">
          <Tooltip
            class="goal-head__tooltip"
            :msg="`&quot;Голосование&quot; - этап, когда работа с подачей идей завершена, но можно голосовать за понравившуюся идею. Количество голосов ограничено!`"
          />
        </div>
      </div>
      <div class="uni-timeline__date">
        {{ getSeason.votingDate | formatDateBegin }}-{{
          getSeason.finalDate | formatDateEnd
        }}
      </div>
    </button>
    <button class="uni-timeline__item">
      <div class="uni-timeline__title">
        Результаты
        <div class="uni-timeline__tooltip">
          <Tooltip
            class="goal-head__tooltip"
            :msg="`&quot;Результаты&quot; - отображение итогов голосования`"
          />
        </div>
      </div>
      <div class="uni-timeline__date">
        <template v-if="getSeason.finalDate">
          {{ getSeason.finalDate | formatDateFinalEnd }}
        </template>
        <template v-else>-</template>
      </div>
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Tooltip from "@/components/Tooltip.vue";
import { format, addDays } from "date-fns";
import { ru } from "date-fns/locale";

export default {
  name: "IdeaTimeline",
  components: {
    Tooltip,
  },
  filters: {
    formatDateBegin(date) {
      if (!date) return "";
      return format(new Date(date), "dd.MM", {
        locale: ru,
      });
    },
    formatDateEnd(date) {
      if (!date) return "";
      return format(addDays(new Date(date), -1), "dd.MM.yyyy", {
        locale: ru,
      });
    },
    formatDateFinalEnd(date) {
      if (!date) return "";
      return format(new Date(date), "dd.MM.yyyy", {
        locale: ru,
      });
    },
  },
  created() {
    this.IDEA_SEASON_FETCH();
  },
  computed: {
    ...mapGetters(["getSeason", "getSeasonStatus"]),
  },
  methods: {
    ...mapActions(["IDEA_SEASON_FETCH"]),
  },
};
</script>
