import request from "@/utils/request";

export function GetOwnIdeas(params) {
  return request({
    url: "/api/Player/Ideas/GetOwnIdeas",
    method: "get",
    params,
  });
}

export function CreateIdea(data) {
  return request({
    url: "/api/Player/Ideas/CreateIdea",
    method: "post",
    data,
  });
}

export function SendToModeration(params, data) {
  return request({
    url: "/api/Player/Ideas/SendToModeration",
    method: "post",
    params,
    data,
  });
}

export function UpdateIdea(params, data) {
  return request({
    url: "/api/Player/Ideas/UpdateIdea",
    method: "put",
    params,
    data,
  });
}

export function DeleteIdea(params) {
  return request({
    url: "/api/Player/Ideas/DeleteIdea",
    method: "post",
    params,
  });
}
